import React from "react";
import "./CaptainSecondRight.css";
import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
const CaptainSecondRight = ({ captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-t-s">
      <div>
        <span>Not-Verified</span>
      </div>
      <span className="captain-accept-image-percentage-span">
        {captain?.rcCardVerified ? "30%" : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Vehicle Rc Verification",
              captain: captain,
              whatDisplayDocs: "rc",
            })
          )
        }
        color="gold"
        size={25}
      />
    </div>
  );
};

export default CaptainSecondRight;
