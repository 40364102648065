import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
import "./CaptainSecondLeft.css";
export const CaptainSecondLeft = ({ captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-f-s">
      <span className="captain-accept-image-percentage-span">
        {captain?.rcCardVerified ? "30%" : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Vehicle Rc Verification",
              captain: captain,
              whatDisplayDocs: "rc",
            })
          )
        }
        color="gold"
        size={25}
      />
      <div>
        <span>Not-Verified</span>
      </div>
    </div>
  );
};
