import React from "react";
import "./CaptainAceptTableImageCard.css";

import CaptainImageDisplay from "../CaptainAcceptTableSingleCard/CaptainImageDisplay/CaptainImageDisplay";
import { useDispatch } from "react-redux";

import { useCaptainAcceptImageCard } from "./CaptainAcceptImageCard.hook";
import { CaptainFirstLeft } from "./Components/CaptainFirstLeft/CaptainFirstLeft";
import { CaptainSecondLeft } from "./Components/CaptainSecondLeft/CaptainSecondLeft";
import { CaptainThirdLeft } from "./Components/CaptainThirdLeft/CaptainThirdLeft";
import CaptainBoxCenter from "./Components/CaptainBoxCenter/CaptainBoxCenter";
import CaptainFirstRight from "./Components/CaptainFirstRight/CaptainFirstRight";
import CaptainSecondRight from "./Components/CaptainSecondRight/CaptainSecondRight";
import CaptainRightThird from "./Components/CaptainRightThird/CaptainRightThird";

const CaptainAcceptImageCard = ({ captain, userRole }) => {
  const dispatch = useDispatch();
  const { verificationData } = useCaptainAcceptImageCard();

  return (
    <div className="captain-accept-image-card">
      <CaptainImageDisplay captain={captain} />
      <div className="captain-accept-image-second-card">
        <div className="captain-accept-image-second-f-card">
          <CaptainFirstLeft
            userRole={userRole}
            captain={captain}
            dispatch={dispatch}
          />
          {userRole === "captain" && (
            <>
              <CaptainSecondLeft captain={captain} dispatch={dispatch} />
              <CaptainThirdLeft captain={captain} dispatch={dispatch} />
            </>
          )}
        </div>
        <CaptainBoxCenter
          verificationData={verificationData}
          userRole={userRole}
        />
        <div className="captain-accept-image-second-f-card">
          <CaptainFirstRight userRole={userRole} dispatch={dispatch} />
          {userRole === "captain" && (
            <>
              <CaptainSecondRight userRole={userRole} dispatch={dispatch} />
              <CaptainRightThird userRole={userRole} dispatch={dispatch} />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CaptainAcceptImageCard;
