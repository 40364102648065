import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
import "./CaptainFirstLeft.css";
export const CaptainFirstLeft = ({ userRole, captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-f-f">
      <span className="captain-accept-image-percentage-span">
        {userRole === "captain"
          ? captain?.panAadharCardVerified
            ? "30%"
            : "0%"
          : captain?.panAadharCardVerified
          ? "100%"
          : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Pan Aadhar Card Verification",
              captain: captain,
              whatDisplayDocs: "pan-aadhar",
            })
          )
        }
        color="gold"
        size={25}
      />
      <div>
        <span>
          {captain?.panAadharCardVerified ? "Verified" : "Not-Verified"}
        </span>
      </div>
    </div>
  );
};
