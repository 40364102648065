import React from "react";
import "./CaptainFirstRight.css";
import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
const CaptainFirstRight = ({ captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-f-t">
      <div>
        <span>Not-Verified</span>
      </div>
      <span className="captain-accept-image-percentage-span">
        {" "}
        {captain?.panAadharCardVerified ? "30%" : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Pan Aadhar Card Verification",
              captain: captain,
              whatDisplayDocs: "pan-aadhar",
            })
          )
        }
        color="gold"
        size={25}
      />
    </div>
  );
};

export default CaptainFirstRight;
