import React from "react";
import "./CaptainBoxCenter.css";
const CaptainBoxCenter = ({ verificationData, userRole }) => {
  return (
    <div className="captain-accept-image-second-center">
      {verificationData
        .filter(
          (data) => userRole === "captain" || data.title === "Pan/Aadhar - Card"
        )
        .map((eachData) => (
          <div key={eachData.title}>
            <h2>{eachData.title}</h2>
            <span>{eachData.description}</span>
          </div>
        ))}
      {/* <Chart options={options} series={update} type="pie" width="100%" /> */}
    </div>
  );
};

export default CaptainBoxCenter;
