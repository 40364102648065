import React from "react";
import "./CaptainRightThird.css";
import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
const CaptainRightThird = ({ captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-ff-tt">
      <div>
        <span>Not-Verified</span>
      </div>
      <span className="captain-accept-image-percentage-span">
        {captain?.licenseCardVerified ? "40%" : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Driving Licence Verification",
              captain: captain,
              whatDisplayDocs: "licence",
            })
          )
        }
        color="gold"
        size={25}
      />
    </div>
  );
};

export default CaptainRightThird;
