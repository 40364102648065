import axios from "axios";

// export const API = axios.create({
//   baseURL: "http://examwebsiteapi.nuhvin.com",
// });

export const API = axios.create({
  baseURL: "https://womenrapido.nuhvin.com",
});

export const imageUrl = "https://womenrapido.nuhvin.com";
