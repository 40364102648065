import { FaCaretDown } from "react-icons/fa";
import { openAdharModalFunc } from "../../../../../../Redux/features/WOR/ModalOpenSlice";
import "./CaptainThirdLeft.css";
export const CaptainThirdLeft = ({ captain, dispatch }) => {
  return (
    <div className="captain-accept-image-second-ff-t">
      <span className="captain-accept-image-percentage-span">
        {captain?.licenseCardVerified ? "40%" : "0%"}
      </span>
      <FaCaretDown
        onClick={() =>
          dispatch(
            openAdharModalFunc({
              title: "Driving Licence Verification",
              captain: captain,
              whatDisplayDocs: "licence",
            })
          )
        }
        color="gold"
        size={25}
      />
      <div>
        <span>Not-Verified</span>
      </div>
    </div>
  );
};
